//f with caching
import React, { useEffect, useState, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { CaretRight } from "@phosphor-icons/react";
import { UseRemoveTildes } from "../utilities/UseRemoveTildes";
import { getCategoriesWithoutPaginationA } from "../methods";

//  cache object to store data and pagination state
const categoryCache = {
  data: [],
  lastPage: 1,
  lastFetch: null,
  hasMore: true,
  totalPages: null,
  maxAge: 5 * 60 * 1000, // 5 minutes
};

const LazyLoadingMenu = ({
  colorMenuDesplegable,
  colorTextoMenuDesplegable,
}) => {
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState({});
  const observer = useRef();
  const menuRef = useRef(null);

  const isCacheValid = useCallback(() => {
    if (!categoryCache.lastFetch) return false;
    const now = new Date().getTime();
    return now - categoryCache.lastFetch < categoryCache.maxAge;
  }, []);

  const lastCategoryRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchCategories(currentPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore, currentPage]
  );

  const fetchCategories = async (page) => {
    if (loading) return;

    // checks if we have cached data for this page
    if (page <= categoryCache.lastPage && isCacheValid()) {
      setCategories(categoryCache.data);
      setCurrentPage(page);
      setHasMore(categoryCache.hasMore);
      return;
    }

    setLoading(true);

    try {
      const response = await getCategoriesWithoutPaginationA(page);

      // If we're continuing from cached data, append to it
      const newCategories =
        page === 1 ? response.data : [...categoryCache.data, ...response.data];

      // updates cache with new data and state
      categoryCache.data = newCategories;
      categoryCache.lastPage = page;
      categoryCache.lastFetch = new Date().getTime();
      categoryCache.hasMore = page < response.paginaUltima;
      categoryCache.totalPages = response.paginaUltima;

      setCategories(newCategories);
      setHasMore(categoryCache.hasMore);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // checks if we have valid cached data on mount
    if (isCacheValid()) {
      setCategories(categoryCache.data);
      setCurrentPage(categoryCache.lastPage);
      setHasMore(categoryCache.hasMore);
    } else {
      // resets cache if invalid
      categoryCache.data = [];
      categoryCache.lastPage = 1;
      categoryCache.hasMore = true;
      categoryCache.totalPages = null;
      fetchCategories(1);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);


  const handleMobileClick = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const handleMouseEnter = (category) => {
    const hasSubcategories =
      Object.values(category.subcategorias || {}).length > 0;
    setHoveredCategory(hasSubcategories ? category : null);
  };

  const handleMouseLeave = (e) => {
    const submenu = document.querySelector(".submenu-container");
    if (submenu) {
      const menuRect = menuRef.current.getBoundingClientRect();
      const submenuRect = submenu.getBoundingClientRect();

      if (
        e.clientX >= menuRect.right &&
        e.clientY >= submenuRect.top &&
        e.clientY <= submenuRect.bottom
      ) {
        return;
      }
    }
    setHoveredCategory(null);
  };

  const handleCategoryClick = (category) => {
    const hasSubcategories =
      Object.values(category.subcategorias || {}).length > 0;

    if (!hasSubcategories) {
      const categoryName = category.name.toLowerCase().replace(/\s+/g, "-");
      const categoryId = category.id;
      window.location.href = `/productos/categorias/${categoryName}/${categoryId}/1`;
    } else {
      setExpandedCategories((prev) => ({
        ...prev,
        [category.id]: !prev[category.id],
      }));
    }
  };

  return (
    <div className="relative flex" onMouseLeave={handleMouseLeave}>
      <div
        ref={menuRef}
        style={{
          backgroundColor: colorMenuDesplegable,
          color: colorTextoMenuDesplegable,
        }}
        className="max-md:w-[100%] min-w-[150px] w-max flex flex-col z-10 bg-white p-4 rounded border border-gray-200 max-h-[300px] overflow-y-auto"
      >
        {categories.map((category, index) => {
          const hasSubcategories =
            Object.values(category.subcategorias || {}).length > 0;
          const isLastElement = index === categories.length - 1;

          return (
            <div
              key={category.id}
              ref={isLastElement ? lastCategoryRef : null}
              onMouseEnter={() => handleMouseEnter(category)}
              className="relative group"
            >
              <p
                onClick={() => handleCategoryClick(category)}
                className="flex items-center justify-between rounded-[5px] text-[14px] hover:bg-[#505050] hover:text-white py-1 px-2 cursor-pointer"
              >
                {category.name} {hasSubcategories && <CaretRight />}
              </p>

              {hasSubcategories && expandedCategories[category.id] && (
                <div className="md:hidden pl-4 pt-2">
                  {Object.values(category.subcategorias).map((sub) => (
                    <NavLink
                      key={sub.subcategoriaid}
                      className="block rounded-[5px] text-[14px] hover:bg-[#505050] hover:text-white py-1 px-2"
                      to={`/productos/categorias/${category.name.toLowerCase()}/${UseRemoveTildes(
                        sub.nombre.replace(/\s+/g, "-").replace(/\//g, "-")
                      )}/${sub.categoriaid}/${sub.subcategoriaid}/1`}
                    >
                      {sub.nombre}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          );
        })}
        {loading && <div className="text-center py-2">Loading...</div>}
      </div>

      {hoveredCategory && (
        <div
          style={{
            backgroundColor: colorMenuDesplegable,
            color: colorTextoMenuDesplegable,
            top: "0",
            height: menuRef.current ? menuRef.current.offsetHeight : "auto",
          }}
          className="submenu-container hidden md:block absolute left-full min-w-[200px] w-max z-20 bg-white p-4 rounded border border-gray-200 overflow-y-auto shadow-lg"
        >
          {Object.values(hoveredCategory.subcategorias).map((sub) => (
            <NavLink
              key={sub.subcategoriaid}
              className="block rounded-[5px] text-[14px] hover:bg-[#505050] hover:text-white py-1 px-2"
              to={`/productos/categorias/${hoveredCategory.name.toLowerCase()}/${UseRemoveTildes(
                sub.nombre.replace(/\s+/g, "-").replace(/\//g, "-")
              )}/${sub.categoriaid}/${sub.subcategoriaid}/1`}
            >
              {sub.nombre}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default LazyLoadingMenu;

//w original
// import React, { useEffect, useState, useRef, useCallback } from "react";
// import { NavLink } from "react-router-dom";
// import { CaretRight } from "@phosphor-icons/react";
// import { UseRemoveTildes } from "../utilities/UseRemoveTildes";
// import { getCategoriesWithoutPaginationA } from "../methods";

// const LazyLoadingMenu = ({
//   colorMenuDesplegable,
//   colorTextoMenuDesplegable,
// }) => {
//   const [categories, setCategories] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [hasMore, setHasMore] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const [hoveredCategory, setHoveredCategory] = useState(null);
//   const [expandedCategories, setExpandedCategories] = useState({});
//   const observer = useRef();
//   const menuRef = useRef(null);

//   const lastCategoryRef = useCallback(
//     (node) => {
//       if (loading) return;
//       if (observer.current) observer.current.disconnect();

//       observer.current = new IntersectionObserver((entries) => {
//         if (entries[0].isIntersecting && hasMore) {
//           fetchCategories(currentPage + 1);
//         }
//       });

//       if (node) observer.current.observe(node);
//     },
//     [loading, hasMore]
//   );

//   const fetchCategories = async (page) => {
//     if (loading) return;
//     setLoading(true);

//     try {
//       const response = await getCategoriesWithoutPaginationA(page);
//       setCategories((prev) => [...prev, ...response.data]);
//       setHasMore(page < response.paginaUltima);
//       setCurrentPage(page);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCategories(1);
//     return () => {
//       if (observer.current) observer.current.disconnect();
//     };
//   }, []);

//   const handleMobileClick = (categoryId) => {
//     setExpandedCategories((prev) => ({
//       ...prev,
//       [categoryId]: !prev[categoryId],
//     }));
//   };

//   const handleMouseEnter = (category) => {
//     const hasSubcategories =
//       Object.values(category.subcategorias || {}).length > 0;
//     // Only set hoveredCategory if there are subcategories, otherwise set it to null
//     setHoveredCategory(hasSubcategories ? category : null);
//   };

//   const handleMouseLeave = (e) => {
//     // Only hide the submenu if the mouse isn't moving to the submenu
//     const submenu = document.querySelector(".submenu-container");
//     if (submenu) {
//       const menuRect = menuRef.current.getBoundingClientRect();
//       const submenuRect = submenu.getBoundingClientRect();

//       // Check if mouse is moving towards submenu
//       if (
//         e.clientX >= menuRect.right &&
//         e.clientY >= submenuRect.top &&
//         e.clientY <= submenuRect.bottom
//       ) {
//         return;
//       }
//     }
//     setHoveredCategory(null);
//   };

//   const handleCategoryClick = (category) => {
//     const hasSubcategories =
//       Object.values(category.subcategorias || {}).length > 0;

//     if (!hasSubcategories) {
//       // Handle category click for categories without subcategories
//       const categoryName = category.name.toLowerCase().replace(/\s+/g, "-");
//       const categoryId = category.id;
//       window.location.href = `/productos/categorias/${categoryName}/${categoryId}/1`;
//     } else {
//       // If the category has subcategories, expand or collapse them
//       setExpandedCategories((prev) => ({
//         ...prev,
//         [category.id]: !prev[category.id],
//       }));
//     }
//   };

//   return (
//     <div className="relative flex" onMouseLeave={handleMouseLeave}>
//       {/* Main Categories Container */}
//       <div
//         ref={menuRef}
//         style={{
//           backgroundColor: colorMenuDesplegable,
//           color: colorTextoMenuDesplegable,
//         }}
//         className="max-md:w-[100%] min-w-[150px] w-max flex flex-col z-10 bg-white p-4 rounded border border-gray-200 max-h-[300px] overflow-y-auto"
//       >
//         {categories.map((category, index) => {
//           const hasSubcategories =
//             Object.values(category.subcategorias || {}).length > 0;
//           const isLastElement = index === categories.length - 1;

//           return (
//             <div
//               key={category.id}
//               ref={isLastElement ? lastCategoryRef : null}
//               onMouseEnter={() => handleMouseEnter(category)}
//               className="relative group"
//             >
//               <p
//                 onClick={() => handleCategoryClick(category)} // Updated to handle click
//                 className="flex items-center justify-between rounded-[5px] text-[14px] hover:bg-[#505050] hover:text-white py-1 px-2 cursor-pointer"
//               >
//                 {category.name} {hasSubcategories && <CaretRight />}
//               </p>

//               {/* Mobile Subcategories */}
//               {hasSubcategories && expandedCategories[category.id] && (
//                 <div className="md:hidden pl-4 pt-2">
//                   {Object.values(category.subcategorias).map((sub) => (
//                     <NavLink
//                       key={sub.subcategoriaid}
//                       className="block rounded-[5px] text-[14px] hover:bg-[#505050] hover:text-white py-1 px-2"
//                       to={`/productos/categorias/${category.name.toLowerCase()}/${UseRemoveTildes(
//                         sub.nombre.replace(/\s+/g, "-").replace(/\//g, "-")
//                       )}/${sub.categoriaid}/${sub.subcategoriaid}/1`}
//                     >
//                       {sub.nombre}
//                     </NavLink>
//                   ))}
//                 </div>
//               )}
//             </div>
//           );
//         })}
//         {loading && <div className="text-center py-2">Loading...</div>}
//       </div>

//       {/* Desktop Subcategories */}
//       {hoveredCategory && (
//         <div
//           style={{
//             backgroundColor: colorMenuDesplegable,
//             color: colorTextoMenuDesplegable,
//             top: "0",
//             height: menuRef.current ? menuRef.current.offsetHeight : "auto",
//           }}
//           className="submenu-container hidden md:block absolute left-full min-w-[200px] w-max z-20 bg-white p-4 rounded border border-gray-200 overflow-y-auto shadow-lg"
//         >
//           {Object.values(hoveredCategory.subcategorias).map((sub) => (
//             <NavLink
//               key={sub.subcategoriaid}
//               className="block rounded-[5px] text-[14px] hover:bg-[#505050] hover:text-white py-1 px-2"
//               to={`/productos/categorias/${hoveredCategory.name.toLowerCase()}/${UseRemoveTildes(
//                 sub.nombre.replace(/\s+/g, "-").replace(/\//g, "-")
//               )}/${sub.categoriaid}/${sub.subcategoriaid}/1`}
//             >
//               {sub.nombre}
//             </NavLink>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default LazyLoadingMenu;
