import SliderBanners from "./SliderBanners";
import MostSoldProducts from "./MostSoldProducts";
import CarouselBrandCategory from "./CarouselBrandCategory";
import { CatalogProducts } from "./CatalogProducts";
import InfoEcommerce from "./InfoEcommerce";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { UseScrollToTop } from "./../utilities/UseScrollToTop";
import { ModalMaintenance } from "./ModalMaintenance";
import CarouselProductsHome from "./CarouselProductsHome";
import Landing from "./Landing";
import { useState } from "react";
import { getDataStore } from "../methods";
import { setDataStore } from "../actions";
import { useEffect } from "react";
import SignUp from "./SignUp";

export default function Home({ showLanding, setShowLanding, hideLanding }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  // console.log('USER >>> ', user);
  let dataStore = useSelector((state) => state.dataStore);
  let { actualPage } = useParams();
  let { productName } = useParams();

  //f Fetch the latest dataStore on component mount
  useEffect(() => {
    async function fetchData() {
      const latestDataStore = await getDataStore();
      dispatch(setDataStore(latestDataStore));
    }

    fetchData();
  }, [dispatch]);

  //f Update `showLanding` state dynamically when `dataStore` changes
  useEffect(() => {
    setShowLanding(dataStore?.ecommerce_info2 !== "0");
  }, [dataStore?.ecommerce_info2, setShowLanding]);

  //w original (["Marcas"] es default, por eso siempre se muestra)
  // let titles =
  //   dataStore?.default_categorias === "1" && dataStore?.default_marcas === "1"
  //     ? ["Categorías", "Marcas"]
  //     : dataStore?.default_categorias === "1" &&
  //       dataStore?.default_marcas === "0"
  //     ? ["Categorías"]
  //     : ["Marcas"];

  //f fixed
  let titles =
    dataStore?.default_categorias === "1"
      ? dataStore?.default_marcas === "1"
        ? ["Categorías", "Marcas"]
        : ["Categorías"]
      : dataStore?.default_marcas === "1"
      ? ["Marcas"]
      : [];

  let enMantenimiento = dataStore?.default_mantenimiento;

  localStorage.setItem("productoIdReturn", "0");
  localStorage.setItem("productoNameReturn", "null");

  //f checks if user is logged in to hide landing
  if (user && user.name) {
    return (
      <>
        <ModalMaintenance show={enMantenimiento} />
        <UseScrollToTop />
        <SliderBanners />
        <InfoEcommerce />
        {titles.length > 0 ? <CarouselBrandCategory titles={titles} /> : null}
        {dataStore?.default_masvendido === "1" ? (
          <MostSoldProducts titles={["más vendidos"]} />
        ) : null}
        <CatalogProducts />
      </>
    );
  }

  // If no user is logged in, show the landing page
  return (
    <>
      {showLanding ? (
        <Landing hideLanding={hideLanding} />
      ) : (
        <>
          <ModalMaintenance show={enMantenimiento} />
          <UseScrollToTop />
          <SliderBanners />
          <InfoEcommerce />
          {titles.length > 0 ? <CarouselBrandCategory titles={titles} /> : null}
          {dataStore?.default_masvendido === "1" ? (
            <MostSoldProducts titles={["más vendidos"]} />
          ) : null}
          <CatalogProducts />
        </>
      )}
    </>
  );
}
