//f only one section

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Landing({ hideLanding }) {
  const [isVisible, setIsVisible] = useState(false);

  const dataStore = useSelector((state) => state.dataStore);

  const title = dataStore?.informacion_lista?.[0] || "Welcome!";
  const paragraph =
    dataStore?.ecommerce_informacion2 || "Discover what we have for you today!";
  const images = [
    dataStore?.imagen1_informacion2,
    dataStore?.imagen2_informacion2,
  ].filter((img) => img);

  const primaryColor = dataStore?.ecommerce_colorPrimario || "#BF407C";
  const secondaryColor = dataStore?.ecommerce_colorSecundario || "#407CBF";
  const tertiaryColor = dataStore?.ecommerce_colorTextoSubNav || "#BF407C";
  const cuaternaryColor = dataStore?.ecommerce_colorFondoSubNav || "fff"

  useEffect(() => {
    setIsVisible(true);
    window.scrollTo(0, 0); // Force scroll to the top
  }, []);

  const handleClick = () => {
    setIsVisible(false);
    setTimeout(hideLanding, 500);
  };

  return (
    <div
      className={`min-h-screen flex items-center justify-center transition-opacity duration-500 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      style={{
        backgroundImage: images[0]
          ? `url('https://devel.gestionfactura.com/archivos/info/cart/118/${images[0]}')`
          : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: secondaryColor,
      }}
    >
      <section className="relative z-10 w-full max-w-7xl px-8 py-16 bg-black bg-opacity-60 rounded-lg shadow-lg flex flex-col md:flex-row items-center gap-8">
        {/* Text Content */}
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h1
            style={{ color: primaryColor }}
            className="text-6xl font-bold mb-8"
          >
            {title}
          </h1>
          <p
            style={{ color: tertiaryColor }}
            className="text-lg md:text-xl mb-8 leading-relaxed"
          >
            {paragraph}
          </p>

          {/* Buttons */}
          <div className="flex gap-4 justify-center md:justify-start">
            {/* Outlined 'Ingresar' Button */}
            <button
              style={{
                backgroundColor: primaryColor,
                color: "#fff",
              }}
              className="px-10 py-4 text-xl font-semibold rounded-lg hover:scale-105 transition-all duration-300"
              onClick={handleClick}
            >
              Explorar
            </button>

            {/* Filled 'Registrate' Button */}
            {/* <Link to="iniciarsesion">
              <button
              style={{
                borderColor: cuaternaryColor,
                color: cuaternaryColor,
                backgroundColor: "transparent",
                boxShadow: "0 0 0 0 transparent",
              }}
              className="px-10 py-4 text-xl font-semibold rounded-lg border-2 hover:scale-105 hover:shadow-[0_0_12px_2px_rgba(255,255,255,0.5)] transition-all duration-300"
              >
                Ingresar
              </button>
            </Link> */}
          </div>
        </div>

        {/* Side Image */}
        {images[1] && (
          <div
            className="w-full md:w-1/2 overflow-hidden rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 cursor-pointer"
            onClick={handleClick}
          >
            <img
              src={`https://devel.gestionfactura.com/archivos/info/cart/118/${images[1]}`}
              alt="Side Image"
              className="w-full h-80 object-cover"
            />
          </div>
        )}
      </section>
    </div>
  );
}

//f with extra text  (BASE)

// import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";

// export default function Landing({ hideLanding }) {
//   const [isVisible, setIsVisible] = useState(false);

//   const dataStore = useSelector((state) => state.dataStore);

//   /* informacion_lista es un array
//   -item 1 === titulo (en primera <section>)
//   -item 2 === titulo 2 (en segunda <section>)
//   -item 3 === parrafo 2 (parrafo para la segunda seccion)

//   -el parrafo para la primera seccion sigue estando en dataStore?.ecommerce_informacion2
//   */
//   const title = dataStore?.informacion_lista?.[0] || "Welcome!";
//   const paragraph =
//     dataStore?.ecommerce_informacion2 || "Discover what we have for you today!";
//   const title2 = dataStore?.informacion_lista?.[1] || "Discover More";
//   const paragraph2 =
//     dataStore?.informacion_lista?.[2] ||
//     "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
//   const images = [
//     dataStore?.imagen1_informacion2,
//     dataStore?.imagen2_informacion2,
//   ].filter((img) => img);

//   const primaryColor = dataStore?.ecommerce_colorPrimario || "#BF407C";
//   const secondaryColor = dataStore?.ecommerce_colorSecundario || "#407CBF";
//   const tertiaryColor = dataStore?.ecommerce_colorTextoSubNav || "#BF407C";

//   useEffect(() => {
//     setIsVisible(true);
//     window.scrollTo(0, 0); // Force scroll to the top
//   }, []);

//   const handleClick = () => {
//     setIsVisible(false);
//     setTimeout(hideLanding, 500);
//   };

//   return (
//     <div
//       style={{ backgroundColor: secondaryColor }}
//       className={`min-h-screen flex flex-col transition-opacity duration-500 ${
//         isVisible ? "opacity-100" : "opacity-0"
//       }`}
//     >
//       {/* Hero Section */}
//       <section className="flex flex-col md:flex-row justify-between px-4 py-12 max-w-10xl mx-auto">
//         {/* Left Side */}
//         <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
//           <h1
//             style={{ color: primaryColor }}
//             className="text-6xl font-bold mb-10"
//           >
//             {title}
//           </h1>
//           {/* Paragraph now visible on all screens */}
//           <p
//             style={{ color: tertiaryColor }}
//             className="text-xl mb-10 leading-relaxed max-w-md mx-auto md:mx-0"
//           >
//             {paragraph}
//           </p>
//           <button 
//             style={{
//               backgroundColor: primaryColor,
//               color: "#fff",
//             }}
//             className="px-12 py-4 text-4xl rounded-lg font-semibold hover:scale-105 transition-all duration-300"
//             onClick={handleClick}
//           >
//             Ingresar
//           </button>
//         </div>

//         {/* Right Side - Hidden on mobile */}
//         {images[0] && (
//           <div
//             className="hidden md:block w-full md:w-1/2 overflow-hidden rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 cursor-pointer"
//             onClick={handleClick}
//           >
//             <img
//               src={`https://devel.gestionfactura.com/archivos/info/cart/118/${images[0]}`}
//               alt="Hero Image"
//               className="w-full h-96 object-cover"
//             />
//           </div>
//         )}
//       </section>

//       {/* Secondary Section - Now inherits background color */}
//       {images[1] && (
//         <section className="flex flex-col md:flex-row justify-between gap-8 px-4 py-12 max-w-10xl mx-auto">
//           {/* Left Side (Image) */}
//           <div
//             className="w-full md:w-1/2 overflow-hidden rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 cursor-pointer"
//             onClick={handleClick}
//           >
//             <img
//               src={`https://devel.gestionfactura.com/archivos/info/cart/118/${images[1]}`}
//               alt="Secondary Image"
//               className="w-full h-80 object-cover"
//             />
//           </div>

//           {/* Right Side (Text) */}
//           <div className="w-full md:w-1/2 text-center md:text-left">
//             <h2
//               style={{ color: primaryColor }}
//               className="text-4xl font-bold mb-4"
//             >
//               {title2}
//             </h2>
//             <p
//               style={{ color: tertiaryColor }}
//               className="text-xl leading-relaxed max-w-md mx-auto md:mx-0"
//             >
//               {paragraph2}
//             </p>
//           </div>
//         </section>
//       )}
//     </div>
//   );
// }



