//f removes ecommerce_info2 (currently used by landing page)
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function DisplayInfoEcommerce({ text, color }) {
  if (!text) return;
  return (
    <div
      style={{ color: color, border: "solid 1px", borderColor: color }}
      className="w-[80vw] mx-auto bg-black text-blue my-2 rounded-[5px] py-2 px-8 text-sm text-center"
    >
      {text.toUpperCase()}
    </div>
  );
}

export default function InfoEcommerce() {
  let dataStore = useSelector((state) => state.dataStore);
  let colorFondoSubNav =
    dataStore?.ecommerce_colorFondoSubNav === "#454545"
      ? "#0661f4"
      : dataStore?.ecommerce_colorFondoSubNav;

  const [infoKeys, setInfoKeys] = useState([]);

  function getArrayInfoKeys(object) {
    const keys = [];
    const regex = /^ecommerce_info\d*$/;
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (regex.test(key) && key !== "ecommerce_info2") {
          // Exclude ecommerce_info2
          keys.push(key);
        }
      }
    }
    return keys;
  }

  useEffect(() => {
    setInfoKeys(getArrayInfoKeys(dataStore));
  }, []);

  return (
    <>
      {infoKeys?.map((key, i) => {
        return (
          +dataStore[key] === 1 && (
            <DisplayInfoEcommerce
              color={colorFondoSubNav}
              key={i}
              text={
                i === 0
                  ? dataStore["ecommerce_informacion"]
                  : dataStore["ecommerce_informacion" + (i + 1)]
              }
            />
          )
        );
      })}
    </>
  );
}


//w original
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";

// function DisplayInfoEcommerce({ text, color }) {
//   if (!text) return;
//   return (
//     <div style={{color:color, border: "solid 1px", borderColor:color}} 
//     className="w-[80vw] mx-auto bg-black text-blue my-2 rounded-[5px] py-2 px-8 text-sm text-center">
//       {text.toUpperCase()}
//     </div>
//   );
// }

// export default function InfoEcommerce() {
//   let dataStore = useSelector((state) => state.dataStore);
//   let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

//   const [infoKeys, setInfoKeys] = useState([]);

//   function getArrayInfoKeys(object) {
//     const keys = [];
//     const regex = /^ecommerce_info\d*$/;
//     for (const key in object) {
//       if (Object.prototype.hasOwnProperty.call(object, key)) {
//         if (regex.test(key)) {
//           keys.push(key);
//         }
//       }
//     }
//     return keys;
//   }

//   useEffect(() => {
//     setInfoKeys(getArrayInfoKeys(dataStore));
//   }, []);

//   return (
//     <>
//       {infoKeys?.map((key, i) => {
//         return (
//           +dataStore[key] === 1 && (
//             <DisplayInfoEcommerce
//               color={colorFondoSubNav}
//               key={i}
//               text={
//                 i === 0
//                   ? dataStore["ecommerce_informacion"]
//                   : dataStore["ecommerce_informacion" + (i + 1)]
//               }
//             />
//           )
//         );
//       })}
//     </>
//   );
// }
